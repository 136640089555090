<template>
	<div>
		<Header :title="isEdit ? '修改演出分类' : '添加演出分类'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 4, xxl: 3}"
				:wrapperCol="{span: 16, xxl: 15 }" @finish="onSubmit">
				<div style="margin-top: 30px;">
					<a-form-item label="分类标题" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="modelRef.name" placeholder="请输入分类标题"></a-input>
					</a-form-item>

					<a-form-item label="分类图标" name="icon" extra="建议图片比例：80 : 112" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-upload v-model:file-list="fileList" list-type="picture-card" action="/admin/ajaxUpload.do"
							accept="image/*" @change="handleChange">
							<div v-if="fileList.length < 1">
								<Icon icon="PlusOutlined"></Icon>
								<div style="margin-top: 8px">Upload</div>
							</div>
						</a-upload>
					</a-form-item>

					<a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
						<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>

					<a-form-item label="分类Banner" name="bannerId">
						<a-form-item-rest>
							<selectImage v-model:value="modelRef.bannerId" :selectItems="imageList" type="radio" :showPosition="11" @change="(dataList) => {imageList = dataList}"></selectImage>
						</a-form-item-rest>
						<div style="margin-top: 10px;" v-if="imageList.length">
							<a-table :pagination="false" :columns="imageColumns" :dataSource="imageList" rowKey="id"
								:scroll="{ x: 500 }">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'showPosition'">
										<div>{{ _transPosition(record.showPosition) }}</div>
									</template>
									<template v-if="column.key === 'jumpPosition'">
										<div>{{ _transJumpType(record.jumpPosition) }}</div>
									</template>
									<template v-if="column.key === 'imageUrl'">
										<a-image :width="50" :src="record.imageUrl" />
									</template>
									<template v-if="column.key === 'action'">
										<a-button type="link" @click="onImageDelete(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item>

					<a-form-item label="状态" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-switch v-model:checked="modelRef.isDisabled" :unCheckedValue="1" :checkedValue="0" checked-children="启用" un-checked-children="禁用" />
					</a-form-item>

					<a-form-item class="ui-recommended" label="热门推荐" name="remarks">
						<a-form-item-rest>
							<selectPerform v-model:value="performIds" :selectItems="performList" @change="(data) => performList = data"></selectPerform>
						</a-form-item-rest>
						<div v-if="performList.length">
							<a-table :columns="performColumns" :dataSource="performList" rowKey="id">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'action'">
										<a-button type="link" @click="onPerformDelete(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item>
				</div>
				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit">{{ isEdit ? '提交' : '提交' }}</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">取消</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon';
	import Header from '@/components/header/header.vue';
	import selectImage from '@/components/select/selectImage';
	import selectPerform from '@/components/select/selectPerform';
	import { getPerformClassifyDetail, savePerformClassify, updatePerformClassify } from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			Header,
			selectImage,
			selectPerform
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				showModal: false,
				rowSelectionType: 'checkbox',
				selectedRowKeys: [],
				fileList: [],
				modelRef: {
					isDisabled: 1,
					bannerId: []
				},
				imageColumns: [{
					title: '广告图标题',
					dataIndex: 'name',
				}, {
					title: '展示位置',
					key: 'showPosition',
				}, {
					title: '跳转位置',
					key: 'jumpPosition',
				}, {
					title: '图片',
					key: 'imageUrl',
				}, {
					title: '操作',
					key: 'action',
				}],
				imageList: [],
				imageIdList: [],
				performIds: [],
				performList: [],
				performColumns: [{
						title: '演出名称',
						dataIndex: 'name',
					},
					{
						title: '演出分类',
						dataIndex: 'classifyName',
					},
					{
						title: '演出标签',
						dataIndex: 'tagName',
					},
					{
						title: '操作',
						key: 'action',
						align: 'center',
					}
				],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						// this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						// this.getData();
					}
				},
			}
		},
		created() {
			if (this.isEdit && this.id) {
				this.getPerformClassifyDetail();
			}
		},
		methods: {
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				if (this.fileList && this.fileList.length) {
					postData.icon = this.fileList[0].url;
				}
				if (postData.bannerId && postData.bannerId.length) {
					postData.bannerId = postData.bannerId[0];
				} else {
					delete postData.bannerId;
				}
				if (this.performIds.length) {
					postData.recommendIds = this.performIds.join(',');
				}
				this.loading = true;
				let ret;
				if (this.isEdit && this.id) {
					ret = await updatePerformClassify(postData);
				} else {
					ret = await savePerformClassify(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功！')
					this.onBack(true);
				}
			},
			async getPerformClassifyDetail() {
				this.loading = true;
				let ret = await getPerformClassifyDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					if (ret.data.icon) {
						this.fileList = [{
							uid: 1,
							name: ret.data.icon,
							url: ret.data.icon
						}];
					}
					if (ret.data.bannerId) {
						ret.data.bannerId = [ret.data.bannerId];
					} else {
						ret.data.bannerId = [];
					}
					this.imageList = ret.data.bannerList || [];
					if (ret.data.performInfoList) {
						this.performList = ret.data.performInfoList;
						this.performIds = ret.data.performInfoList.map(item => {
							return item.id;
						})
					}
					delete ret.data.performInfoList;
					this.modelRef = ret.data;
					this.loading = false;
				}
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			handleChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.icon = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			onImageDelete(item, index) {
				let i = this.modelRef.bannerId.indexOf(item.id);
				this.modelRef.bannerId.splice(i, 1);
				this.imageList.splice(index, 1);
			},
			onPerformDelete(item, index) {
				let i = this.performIds.indexOf(item.id);
				this.performIds.splice(i, 1);
				this.performList.splice(index, 1);
			}
		}
	}
</script>

<style scoped>
	.ui-header {
		padding: 20px 0 10px;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;
		border-bottom: solid 1px #eee;
	}

	.ui-recommended {
		position: relative;
	}

	.ui-seletced {
		margin-left: 10px;
		position: absolute;
		top: 15px;
		color: #aaaaaa;
	}

	.ui-form__item {
		margin-right: 30px;
	}
</style>